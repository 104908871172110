/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Col, message, Row, Spin, Table, Popover, Input, Select, notification, Modal, Drawer, Space } from 'antd';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../components/buttons/buttons';
import { CalendarButtonPageHeader } from '../../components/buttons/calendar-button/calendar-button';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { API, errorParser, makeDelayedHttpGetCall } from '../../utility/utils';
import { UserTableStyleWrapper } from '../pages/style';
import { CardToolbox, Main, TableWrapper } from '../styled';
import _ from 'lodash';
import { AutoComplete } from '../../components/autoComplete/autoComplete';

moment.tz.setDefault('America/Mexico_City');

const Sales = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [ReportsTableData, setReportsTableData] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [searchWords, setSearchWords] = useState('');
  const [agents, setAgents] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [dateRange, setDateRange] = useState(`${moment().format('DD/MM/YYYY')}-${moment().format('DD/MM/YYYY')}`);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedQuoteAgent, setSelectedQuoteAgent] = useState(null);
  const [selectedSalesAgent, setSelectedSalesAgent] = useState(null);
  const [selectedNewClientFilter, setSelectedNewClientFilter] = useState(null);

  const paymentTypeList = [
    'ABONADO',
    'CREDITO',
    'PAGO_CONTADO',
    'TRANSFERENCIA',
    'EFECTIVO',
    'EFECTIVO_FACTURA',
    'EFECTIVO_RECIBO',
    'CHEQUE',
    'TARJETA',
    'COT_SALDO_A_FAVOR',
  ];

  const paymentListDisplayValue = {
    ABONADO: 'Abonado',
    CREDITO: 'Crédito',
    PAGO_CONTADO: 'Contado',
    TRANSFERENCIA: 'Transferencia',
    EFECTIVO: 'Efectivo',
    EFECTIVO_FACTURA: 'Efectivo Factura',
    EFECTIVO_RECIBO: 'Efectivo Recibo',
    CHEQUE: 'Cheque',
    TARJETA: 'Tarjeta',
    COT_SALDO_A_FAVOR: 'Cotizacion',
  };

  const [date, setDate] = useState({
    start: moment()
      .startOf('day')
      .format('X'),
    end: moment()
      .endOf('day')
      .format('X'),
  });
  useEffect(() => {
    fetchPrepareData();
    fetchReports();
  }, []);

  const fetchPrepareData = async () => {
    const res2 = await API.get('work-order/prepare-create');
    setAgents(res2.data.data.salesAgentList);
    setProductTypes(res2.data.data.productTypeList);
  };

  const fetchReports = async () => {
    setFetchingData(true);
    const res = await API.get(
      `reports/sales?&startDate=${date.start}&endDate=${
        date.end
      }&search=${searchWords}&newClients=${selectedNewClientFilter}&paymentMethod=${selectedPaymentMethod ||
        ''}&productTypeId=${selectedProductType || ''}&quoteAgentId=${selectedQuoteAgent || ''}&salesAgentId=${selectedSalesAgent || ''}`,
    );
    setFetchingData(false);
    const arr = [];
    console.log(res);

    res.data.data.rows.forEach(u => {
      arr.push({
        ...u,
        key: uuidv4(),
        invoice: u.invoice || 'N/A',
        quoteAgent: u.quoteAgent || 'N/A',
        salesAgent: u.salesAgent || 'N/A',
        paymentMethod: u.paymentMethod || 'N/A',
        isNewClient: u.isNewClient === null ? 'N/A' : u.isNewClient,
        isClientPartner: u.isClientPartner === null ? 'N/A' : u.isClientPartner,
      });
    });

    setReportsTableData(arr);

    setFetchingData(false);
  };

  const orderTableColumns = [
    {
      title: 'Indice',
      dataIndex: 'idx',
      key: 'idx',
    },
    {
      title: 'Fecha de ingreso',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
    },
    {
      title: 'Total de órdenes',
      dataIndex: 'totalWorkOrders',
      key: 'totalWorkOrders',
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Ciudad',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Tipo de producto',
      dataIndex: 'productType',
      key: 'productType',
    },
    {
      title: 'Muestreador',
      dataIndex: 'sampler',
      key: 'sampler',
    },
    {
      title: 'Fecha De Última Orden De Trabajo',
      dataIndex: 'latestWorkOrderDate',
      key: 'latestWorkOrderDate',
    },
    {
      title: 'Cotización',
      dataIndex: 'quote',
      key: 'quote',
    },

    {
      title: 'Tipo de pago',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (text, record) => <div>{paymentListDisplayValue[text]}</div>,
    },
    {
      title: 'Importe',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Factura',
      dataIndex: 'invoice',
      key: 'invoice',
    },
    {
      title: 'Agente que cotiza',
      dataIndex: 'quoteAgent',
      key: 'quoteAgent',
    },
    {
      title: 'Agente de ventas',
      dataIndex: 'salesAgent',
      key: 'salesAgent',
    },
    {
      title: 'Cliente nuevo',
      dataIndex: 'isNewClient',
      key: 'isNewClient',
    },
    {
      title: 'Cliente socio',
      dataIndex: 'isClientPartner',
      key: 'isClientPartner',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
    },
  ];


  const handleExport = async () => {
    try {
      setLoadingExport(true);
      const res = await API.get(
        `reports/sales/export?&startDate=${date.start}&endDate=${
          date.end
        }&search=${searchWords}&newClients=${selectedNewClientFilter}&paymentMethod=${selectedPaymentMethod ||
          ''}&productTypeId=${selectedProductType?.id || ''}&quoteAgentId=${selectedQuoteAgent || ''}&salesAgentId=${selectedSalesAgent || ''}`,
      );
      window.open(res.data.data.s3Key);
    } catch (e) {
      console.log(e);
    }
    setLoadingExport(false);
  };

  const { Option } = Select;

  useEffect(() => {
    fetchReports();
  }, [currentPage, searchWords, date, selectedNewClientFilter, selectedPaymentMethod, selectedProductType,selectedQuoteAgent,selectedSalesAgent]);

  const handleDateChange = newDate => {
    setDate({
      start: moment(newDate.selection.startDate)
        .startOf('day')
        .format('X'),
      end: moment(newDate.selection.endDate)
        .endOf('day')
        .format('X'),
    });

    const temp = `${moment(newDate.selection.startDate)
      .startOf('day')
      .format('DD/MM/YYYY')}-${moment(newDate.selection.endDate)
      .startOf('day')
      .format('DD/MM/YYYY')}`;
    setDateRange(temp);

    setCurrentPage(1);
  };

  const handleSearch = _.debounce(value => {
    setSearchWords(value);
    setCurrentPage(1);
  }, 500);

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Ventas"
          className="delaysHeader"
          subTitle={
            <div
              className="ordersContentBar"
              style={{ display: 'flex', alignItems: 'center', gap: 10, paddingTop: 20, overflowY: 'hidden' }}
            >
              <CalendarButtonPageHeader value={searchWords} dateRange={dateRange} onChange={handleDateChange} />
              <AutoComplete onSearch={handleSearch} placeholder="Buscar ..." width="22rem" patterns />
              <Col span={4}>
                <p
                  style={{
                    fontSize: 10,
                    position: 'absolute',
                    margin: 0,
                    top: '-18px',
                    zIndex: 1000,
                    color: '#3b3b3b',
                  }}
                >
                  Metodo de pago
                </p>
                <Select
                  value={selectedPaymentMethod}
                  placeholder="Método de pago"
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  onChange={value => {
                    setSelectedPaymentMethod(value);
                  }}
                >
                  {paymentTypeList.map(type => (
                    <Option value={type} key={type}>
                      {paymentListDisplayValue[type]}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={4}>
                <p
                  style={{
                    fontSize: 10,
                    position: 'absolute',
                    margin: 0,
                    top: '-18px',
                    zIndex: 1000,
                    color: '#3b3b3b',
                  }}
                >
                  Tipo de producto
                </p>
                <Select
                  value={selectedProductType}
                  placeholder="Tipo de producto"
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  onChange={value => {
                    console.log(value);
                    setSelectedProductType(value);
                  }}
                >
                  {productTypes.map(type => (
                    <Option value={type.id} key={type.id}>
                      {type.productType}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={3}>
                <p
                  style={{
                    fontSize: 10,
                    position: 'absolute',
                    margin: 0,
                    top: '-18px',
                    zIndex: 1000,
                    color: '#3b3b3b',
                  }}
                >
                  Agente que cotiza
                </p>
                <Select
                  value={selectedQuoteAgent}
                  placeholder="Agente que cotiza"
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  onChange={value => {
                    setSelectedQuoteAgent(value);
                  }}
                >
                  {agents.map(type => (
                    <Option value={type.id} key={type.id}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={3}>
                <p
                  style={{
                    fontSize: 10,
                    position: 'absolute',
                    margin: 0,
                    top: '-18px',
                    zIndex: 1000,
                    color: '#3b3b3b',
                  }}
                >
                  Agente de ventas
                </p>
                <Select
                  value={selectedSalesAgent}
                  placeholder="Agente de ventas"
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  onChange={value => {
                    setSelectedSalesAgent(value);
                  }}
                >
                  {agents.map(type => (
                    <Option value={type.id} key={type.id}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={2}>
                <p
                  style={{
                    fontSize: 10,
                    position: 'absolute',
                    margin: 0,
                    top: '-18px',
                    zIndex: 1000,
                    color: '#3b3b3b',
                  }}
                >
                  Clientes nuevos
                </p>
                <Select
                  value={selectedNewClientFilter}
                  style={{ backgroundColor: '#fff', width: '100%' }}
                  placeholder="Clientes nuevos"
                  onChange={value => {
                    setSelectedNewClientFilter(value);
                  }}
                >
                  <Option value key="true">
                    Si
                  </Option>
                  <Option value={false} key="false">
                    No
                  </Option>
                  <Option value={null} key="nul">
                    Sin definir
                  </Option>
                </Select>
              </Col>
              {selectedNewClientFilter !== null || selectedPaymentMethod || selectedProductType ? (
                <Button
                  className="btn-add_new"
                  size="default"
                  type="ghost"
                  style={{ color: '#000' }}
                  onClick={() => {
                    setSelectedNewClientFilter(null);
                    setSelectedPaymentMethod(null);
                    setSelectedProductType(null);
                    setCurrentPage(1);
                  }}
                >
                  Limpiar filtros
                </Button>
              ) : null}
              {ReportsTableData.length > 0 && (
                <div>
                  <Button
                    className="btn-add_new"
                    size="default"
                    type="primary"
                    key="2"
                    loading={loadingExport}
                    disabled={loadingExport}
                    onClick={handleExport}
                  >
                    Exportar
                  </Button>
                </div>
              )}
              {ReportsTableData?.length > 0 && (
                <span className="">{`${ReportsTableData.length} ventas`} </span>
              )}
            </div>
          }
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <TableWrapper>
                  {fetchingData === false ? (
                    <Table
                      dataSource={ReportsTableData}
                      columns={orderTableColumns}
                      showSizeChanger={false}
                      locale={{ emptyText: 'No se encontraron resultados' }}
                      pagination={{ hideOnSinglePage: true, pageSize: 9000 }}
                    />
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Spin size="large" />
                    </div>
                  )}
                </TableWrapper>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Sales;
